import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";
import { Link } from 'react-router-dom';

function Description() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            The prediction will happen on the immunotherapy treatment type you choose.
            <br />
            <br />
            Dataset should be a Gene expression matrix with values separated by Tab. Rows are named with gene symbols. Columns are named with patient IDs.
            <br />
            <br />
            Currently, we have the models build for some predictions. Proceed to our <Link to="/our_results" className="purple">Results</Link> page to view.
            <br /> 
            <br />
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default Description;
