import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";
import { Link } from 'react-router-dom';

function DescriptionResults() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Currently, we have the models build for below <span className="purple">DRUGS</span> - Select one of the treatments.
            <br /> 
            <br />
            The prediction will happen on the immunotherapy treatment type you choose.
            <br /> 
            <br />
            Proceed to <Link to="/predict_upload" className="purple">Step 1</Link> to upload your own dataset.
            </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default DescriptionResults;
