// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  margin: 0;\n  font-family: \"Raleway\", serif !important;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-image: linear-gradient(to left, rgb(27 20 41), rgb(20 15 35));\n}\n\n", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AACA;EACE,SAAS;EACT,wCAAwC;EACxC,mCAAmC;EACnC,kCAAkC;EAClC,wEAAwE;AAC1E","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap\");\nbody {\n  margin: 0;\n  font-family: \"Raleway\", serif !important;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-image: linear-gradient(to left, rgb(27 20 41), rgb(20 15 35));\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
