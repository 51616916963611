import React, { Component } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import Particle from './Particle';
import { Left } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CiLocationArrow1 } from "react-icons/ci";
import { HiOutlineMail } from "react-icons/hi";
import { BiPhone } from "react-icons/bi";

export default class Contact extends Component {
  handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });
    console.log(data);
      try {
        const response = await fetch('/api/contact', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
        if (response.ok) {
          console.log('Form submitted successfully');
        } else {
          console.error('Failed to submit form');
        }
      } catch (error) {
        console.error('Failed to submit form:', error);
      }
    };
  render() {
    return (
    <Container fluid className="contact-section">
        <Particle />
        <h1 style={{ fontSize: "1.8em", paddingBottom: "20px" }}>
              <strong className="purple">Contact Us</strong>
        </h1>
        <p style={{ fontSize: "1.2em" }}>Fill the form or Reach out to us for your queries</p>
        <Row style={{ justifyContent: "center", padding: "15px" }}>
        <Col
            md={7}
            style={{
              justifyContent: "left",
              paddingTop: "20px",
              paddingBottom: "50px",
            }}
          >
      <form onSubmit={this.handleSubmit}>
      <div className="mb-3 row">
        <div className="col-md-6">
          <label>Email address</label>
          <input
            type="email"
            className="form-control"
            placeholder="Enter email"
          />
        </div>
        <div className="col-md-6">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Name"
          />
        </div>
      </div>
        <div className="mb-3">
          <label>Message</label>
          <textarea
            type="message"
            className="form-control"
            style={{ height: "5rem" }}
            rows={6} 
            placeholder="Describe your issues or Give feedback"
          />
        </div>
        <div className="d-grid">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
      </Col>
      <Col
            md={5}
            style={{
                justifyContent: "left",
                paddingTop: "20px",
                paddingBottom: "50px",
              }}
          > 
              <p className='purple'>Email your queries and feedback to below</p>
              <p className='white'><a href="mailto:JoshiTr@missouri.edu" style={{ color: 'inherit'}}><HiOutlineMail />&nbsp;JoshiTr@missouri.edu</a></p>
              <p className='white'><a href="mailto:yjm85@missouri.edu" style={{ color: 'inherit' }}><HiOutlineMail />&nbsp;yjm85@missouri.edu</a></p>
          </Col>
      </Row>
      </Container>
    )
  }
}